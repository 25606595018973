import { useEffect, useState } from 'react';

import { Flex, Header, Spinner, Table, TextInput } from '@samsungads/beau';

import { csvToJSON } from '../common/utils/csv';
import { Content, ContentArea, SearchWrapper, TableHeaderWrapper } from '../styles';

interface TableData {
  CreativeID: string;
  CandidateName: string;
  AssetName: string;
  CreativeURL: string;
  DateTime: string;
}

const Creatives = () => {
  const [rawData, setRawData] = useState<TableData[]>([]);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch( `/ui_reports/creatives_report.csv` )
      .then( response => response.text() )
      .then( responseText => {
        const data = csvToJSON<TableData>(responseText);
        setRawData(data);
        setTableData(data);
        setLoading(false);
      }).catch(err => {
        console.log(err);
      });
  }, []);

  const handleSearch = (e: string) => {
    setSearchInput(e);
    if (e.length > 0) {
      setTableData(rawData.filter(data => data?.CreativeID?.includes(e) || data?.CandidateName?.includes(e)));
    } else {
      setTableData(rawData);
    }
    setPageNumber(0);
  };

  return (
    <ContentArea>
      <Header>
        <Header.BackButton url="/" />
        <Header.Heading>
          <Header.Heading.Title>Creatives</Header.Heading.Title>
        </Header.Heading>
      </Header>
      <Content>
        <TableHeaderWrapper>
          <SearchWrapper>
            <TextInput
              autoComplete="on"
              name="name-input"
              placeholder="Search by Creative ID/Candidate Name"
              value={searchInput}
              onChange={handleSearch}
              data-testid="creative-search"
            />
          </SearchWrapper>
        </TableHeaderWrapper>
        {
          loading ? 
          <Flex horizontalAlign='center'>
            <Spinner />
          </Flex>
          :
            <Table
              columns={[
                {
                  Header: 'Creative ID',
                  accessor: 'CreativeID',
                  width: 80
                },
                {
                  Header: 'Candidate Name',
                  accessor: 'CandidateName',
                  width: 80
                },
                {
                  Header: 'Creative URL',
                  accessor: 'AssetName',
                  maxWidth: 360,
                  Cell: (data: { row: { original: TableData } }) => (
                    <a href={data?.row?.original?.CreativeURL}>{data?.row?.original?.CreativeURL}</a>
                  ),
                },
                {
                  Header: 'Upload Date',
                  accessor: 'DateTime',
                  maxWidth: 80,
                },
              ]}
              data={tableData}
              sortable={true}
              onPaginationChange={({pageIndex}) => setPageNumber(pageIndex)}
              pageIndex={pageNumber}
              pagination
            />

        }
      </Content>
    </ContentArea>
  );
};

export { Creatives };
  