import { theme } from '@samsungads/beau';

import styled from 'styled-components';

const ContentArea = styled.div`
  background-color: ${theme.colors.neutrals.lightGrey};
  overflow-y: scroll;
`;

const Content = styled.div`
  margin: 24px;
  background-color: ${theme.colors.neutrals.white};
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  padding-bottom: 2rem;
`;


const OrderedList = styled.ol`
  counter-reset: item;
  margin-top: 10px;
  li {
    display: block;
    margin-bottom: 10px;
  }
  li:before {
    content: counters(item, '.') ' ';
    counter-increment: item;
    font-weight: 600;
    width: 45px;
    display: inline-block;
    margin-left: -45px;
    text-align-last: center;
  }
`;

const ListHeader = styled.span`
  font-weight: 600;
  display: inline-block;
  margin: 10px 0 8px;
`;

const TableHeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 15px 0px;
`;

const SearchWrapper = styled.div`
  width: 400px;
`;

export { ContentArea, Content, OrderedList, ListHeader, TableHeaderWrapper, SearchWrapper };