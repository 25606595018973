import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Creatives } from "./creatives";
import { Privacy } from "./privacy-policy";
import { Reports } from "./reports";

const AppRouting = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Privacy />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/creatives" element={<Creatives />} />
            </Routes>
        </BrowserRouter>
    )
}

export default AppRouting;