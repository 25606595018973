import styled from 'styled-components';

const PrivacyContainer = styled.div`
  padding: 30px 100px;
  overflow-y: auto; 
`;

const OrderedList = styled.ol`
  counter-reset: item;
  margin-top: 10px;
  li {
    display: block;
    margin-bottom: 10px;
  }
  li:before {
    content: counters(item, '.') ' ';
    counter-increment: item;
    font-weight: 600;
    width: 45px;
    display: inline-block;
    margin-left: -45px;
    text-align-last: center;
  }
`;

const ListHeader = styled.span`
  font-weight: 600;
  display: inline-block;
  margin: 10px 0 8px;
`;

export { PrivacyContainer, OrderedList, ListHeader };
