import React, { useEffect, useState } from 'react';

import { Header, Table, Icon, Flex, Spinner } from '@samsungads/beau';

import { csvToJSON } from '../common/utils/csv';

import { Content, ContentArea } from '../styles';

interface TableData {
  ReportName: string;
  ReportLink: string;
  Date: string;
}

const Reports = () => {
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch( `/ui_reports/metrics_report.csv` )
      .then( response => response.text() )
      .then( responseText => {
        const data = csvToJSON<TableData>(responseText);
        setTableData(data);
        setLoading(false);
      }).catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <ContentArea>
      <Header>
        <Header.BackButton url="/" />
        <Header.Heading>
          <Header.Heading.Title>Political Reports</Header.Heading.Title>
        </Header.Heading>
      </Header>
      <Content>
        {
          loading ? 
          <Flex horizontalAlign='center'>
            <Spinner />
          </Flex>
          :
          <Table
            className="testing"
            columns={[
              {
                Header: 'Report Name',
                accessor: 'ReportName',
                width: 360,
                Cell: (data: { row: { original: TableData } }) => (
                  <a href={data?.row?.original?.ReportLink}>{data?.row?.original?.ReportName}</a>
                ),
              },
              {
                Header: 'Upload Date',
                accessor: 'Date',
                maxWidth: 80,
              },
              {
                // Header: 'Actions',
                Header: () => <div style={{ textAlign: 'center', borderRightWidth: 0 }}>Actions</div>,
                accessor: 'action',
                disableSortBy: true,
                maxWidth: 120,
                Cell: (data: { row: { original: TableData } }) => (
                  <Flex horizontalAlign='center'>
                    <a href={data?.row?.original?.ReportLink}>
                      <Icon
                        data-testid="dowloadButtonInsiderIcon"
                        name="Download"
                        color='#1428A0'
                      />
                    </a>
                  </Flex>
                ),
              },
            ]}
            data={tableData}
            sortable={true}
            pagination
          />
          }
      </Content>
    </ContentArea>
  );
};

export { Reports };
  