import React from 'react';
import AppRouting from "./app.routing";

const App = () => {
  return (
    <AppRouting />
  );
}

export default App;
