import React from 'react';
import { Link } from 'react-router-dom';

import { Flex, Typography } from '@samsungads/beau';

import { PrivacyContainer, ListHeader, OrderedList } from './styles';

const Privacy = () => {
  return (
    <PrivacyContainer>
      <Flex horizontalAlign="center" direction="column" gap={4}>
        <Typography variant="heading2strong">
          Samsung Ads Political Transparency
        </Typography>
      </Flex>
      {/* <OrderedList>
        <li>
          <ListHeader>Overviews</ListHeader>
          <span>
            <br />
            Samsung believes in being sincere and honest with our customers. We respect the
            dignity and diversity of individuals and aim to foster a healthy and neutral
            advertising ecosystem that reaches people positively, safely, and playfully at every
            connected moment of discovery with Samsung Ads.
            <br />
            <br />
            The purpose of these guidelines and acceptance policies is to help you create
            advertisement campaigns compatible with Samsung’s brand values and business principles
            and, most importantly, are law abiding and safe for our users.
            <br />
            <br />
            For these reasons, Samsung reserves the right to reject, remove, or request
            modifications to an ad for any reason, in our sole discretion. We periodically update
            our policies, and encourage you stay up to date on them. Nothing written here is meant
            to be construed as legal advice.
          </span>
        </li>
        <li>
          <ListHeader>Your General Obligations</ListHeader>
          <br />
          <OrderedList>
            <li>
              <span>
                As the advertiser, you are fully and completely responsible for ensuring that when
                you submit your advertisements to us, they:
              </span>
              <OrderedList>
                <li>
                  <span>
                    comply with these guidelines and acceptance policies and all applicable laws
                    and regulations including, without limitation, Federal and state laws, the
                    rules of the Federal Communications Commission (FCC), the Federal Trade
                    Commission (FTC), the Food and Drug Administration (FDA), the Federal Election
                    Commission (FEC), and all applicable self-regulatory codes and industry
                    standards for advertising and marketing.
                  </span>
                </li>
                <li>are claim substantiated, truthful, and not deceptive or misleading.</li>
                <li>
                  do not infringe any third-party trademarks, copyrights, rights of publicity,
                  patents, trade secrets, or other intellectual property rights.{' '}
                </li>
                <li>
                  are free from computer viruses, ransomware, worms, trojan horses, spyware, rogue
                  security software, and other malicious programs or apps.
                </li>
              </OrderedList>
            </li>
            <li>
              <span>
                You are responsible for obtaining any licenses and permits required in connection
                with your advertisements.
              </span>
            </li>
            <li>
              <span>
                At the time you submit an advertisement to us, you have reviewed and understand
                the terms of the Samsung privacy policy applicable to the persons receiving
                advertisements placed by you.
              </span>
            </li>
          </OrderedList>
        </li>
        <li>
          <ListHeader>Prohibited Content, Messages, Products, and Services</ListHeader>
          <br />
          Promotion of the following through Samsung Ads is unacceptable:
          <OrderedList>
            <li>
              <b>Adult Content.</b> Ads containing sexually suggestive or provocative messages
              including nudity, obscured or implied sexual acts, explicit sexual language,
              explicit sexual acts, or visible sexual touching.
            </li>
            <li>
              <b>Alcohol.</b> Ads promoting excessive consumption of alcoholic beverages.
            </li>
            <li>
              <ListHeader>Competitive Products and Services. </ListHeader>
              <OrderedList>
                <li>
                  Ads for non-Samsung smart TVs are prohibited on Samsung TV Plus and other
                  Samsung owned and operated inventory.
                </li>
                <li>
                  Ads for non-Samsung smart TVs are prohibited on Samsung owned and operated
                  Native Ad inventory.
                </li>
                <li>
                  Competitive ads for Soundbars, Monitors, Speakers, Pico Projector and their
                  accessories (Earphones, Headphones), Smartphones, Tablets, Wearables (Watches),
                  PCs and their accessories (Earphones, Headphones), Refrigerators, Ovens &
                  Microwaves, Dishwashers, Air-Conditioners, Washers, Dryers, Air Dressers,
                  Vacuums, Air purifiers and Printers are prohibited on Samsung owned and operated
                  Native Ad inventory.
                </li>
                <li>
                  3.3.4. Competitive ads for mobile products or services are prohibited on Samsung
                  owned and operated Mobile Ad inventory.
                </li>
              </OrderedList>
            </li>
            <li>
              <b>Derogatory Messages and Hate Speech.</b> Ads containing direct or indirect
              derogatory statements against any race, gender, ethnicity, nationality, religion,
              disability, or specific persons. Any content promoting violence or advocating
              against a particular group, including groups identified by their (i) race or ethnic
              origin, (ii) religion, (iii) color, (iv) national origin, (v) disability, (vi) age,
              (vii) veteran status, or (viii) sexual orientation or gender identity.
            </li>
            <li>
              <b>False or misleading information.</b> Ads with untruthful, deceptive, or unclear
              messaging.
            </li>
            <li>
              <b>Firearms.</b> Ads for firearms products including (i) guns, (ii) ammunition, and (iii) fireworks.
            </li>
            <li>
              <b>Illegal objects and activities.</b> Ads promoting, containing, or pointing to illegal objects or activities (e.g., how to build a bomb, stealing, robbing, hacking, phreaking, software piracy, illegal substances, or links to any website containing illegal, false or deceptive investment advice or money-making opportunity).
            </li>
            <li>
              <b>Mimicry.</b> Ads mimicking the functionality or warnings of an operating system or other apps.
            </li>
            <li>
              <b>Profane, obscene, or vulgar language</b> (including any related slang).
            </li>
            <li>
              <b>Religious or occultist content, imagery or symbols.</b>
            </li>
            <li>
              <b>Tobacco or tobacco-related products including vapes.</b>
            </li>
            <li>
              <b>Underage use of alcohol, smoking, or drugs.</b>
            </li>
            <li>
              <b>Use of drugs and drug paraphernalia</b>, including (i) illegal drugs, (ii) drug accessories, and (iii) herbal drugs (e.g., marijuana, salvia, and “magic mushrooms”).
            </li>
            <li>
              <b>Violence</b>, including (i) obscene or vulgar language (e.g., excessive swearing), (ii) assault or rape, (iii) injury to human beings or animals, (iv) blood or dismemberment of human beings or animals, (v) torture or killing of human beings or animals, or (vi) bullying.
            </li>
          </OrderedList>
        </li>
        <li>
          <ListHeader>Editorial</ListHeader>
          <br />
          Samsung will reject advertisements containing:
          <OrderedList>
            <li>
              indirect and unclear messages (e.g. ads or destinations that do not name the product, service, or entity they are promoting) or lacking information about the sponsor of the ad if it does not promote a commercial product or service, or if it does promote a commercial product or service, if the sponsor is unclear from the context of advertisement or it is unclear that the advertisement is a commercial message.
            </li>
            <li>
              uncommon or unusual grammar, spelling, and punctuation.
            </li>
            <li>
              images that are blurry, unclear, unrecognizable, or contain illegible text.
            </li>
            <li>
              inconsistency between any ad copy and the landing page.
            </li>
          </OrderedList>
        </li>
        <li>
          <ListHeader>Political Ads</ListHeader>
          <br />
          These guidelines apply to any advertisement that (i) promotes or opposes a political candidate or a political party, (ii) relates to a referendum or elections, or (iii) addresses a political issue of public concern. These guidelines aim to ensure transparency, accountability, and ethical practices in political advertising, while upholding the principles of free speech and fair competition.
          <OrderedList>
            <li>
              <ListHeader>Compliance with Applicable Laws</ListHeader><br />
              All political ads must comply with applicable laws and regulations including without limitation, all national election laws, copyright law, defamation law, and Federal Election Commission regulations and state or local laws and regulations. Samsung may implement additional restrictions in some US states for certain political ads.
            </li>
            <li>
              <ListHeader>Transparency and Disclosure</ListHeader>
              <OrderedList>
                <li>
                  <u>Identity Verification</u>: Advertisers must undergo a verification process to confirm their identity, including providing accurate information about the sponsoring organization or individual, contact details, and any other relevant information as required by applicable laws and regulations. Political ads may not be paid for directly or indirectly by non-resident foreign nationals or entities.
                </li>
                <li>
                  <u>Disclosure Label</u>: Advertiser must include all disclosure required by municipal, state or federal law, including without limitation (i) the indication of the political party in the political ad and/or (ii) any financial contributions made to political candidates or parties as applicable.
                </li>
                <li>
                  <u>Disclaimers</u>: Advertisers must include a verified &quot;Paid for by&quot; disclaimer on these ads to show the full legal name of the entity or person responsible for running the ad. Where legally required, electoral ads must also state whether or not the ad was authorized by a candidate or organization, and election ads not authorized by the candidate must include contact information for the sponsoring organization.
                </li>
                <li>
                  <u>Review Process</u>: Political advertisements will undergo a review process to ensure factual accuracy. Samsung reserves the right to require substantiation of an advertiser&apos;s factual claims. Samsung reserves the right to reject any ad that includes false or misleading claims, and any other ad that, in Samsung&apos;s sole judgment, is not appropriate for its audience.
                </li>
              </OrderedList>
            </li>
            <li>
              <ListHeader>Prohibited Content and Practices</ListHeader><br />
              <OrderedList>
                <li>
                  <u>Deceptive Tactics</u>: Political advertisements must not employ deceptive practices, such as misleading headlines, fake news sites, or manipulated imagery, to mislead or deceive users. The content of the political ads must not impersonate any person or entity, or otherwise misrepresents the affiliation with a person or entity.  Samsung reserves the right to reject any political ads generated by Artificial Intelligence.
                </li>
                <li>
                  <u>Discriminatory Targeting</u>: [Refrain from enabling any] OR [Do not enable] microtargeting of political ads based on sensitive personal attributes such as race, religion, gender, sexual orientation, or political affiliation. 
                </li>
                <li>
                  <u>Defamation and Hate Speech</u>: Political advertisements must comply with local laws and regulations regarding defamation, libel, or hate speech. Content that harasses, intimidates, threatens, promotes violence, discrimination, or incites hatred will be rejected.
                </li>
              </OrderedList>
            </li>
            <li>
              <ListHeader>Retention of Advertiser Records</ListHeader><br />
              You must maintain a record of political advertisers, their advertisements, and related documentation, including without limitation ad content, targeting details, delivery, spend, and other campaign information, for transparency audits, legal compliance, and public inquiries as required by applicable laws and regulations. You must make available this documentation to Samsung where required by state or federal law and, in all cases, upon request.
            </li>
            <li>
              <ListHeader>Periodic Review and Updates</ListHeader><br />
              These political advertising guidelines will be periodically reviewed and updated to align with changing political landscapes, technological advancements, and emerging best practices. We recommend you stay informed about local laws and regulations related to political advertising to ensure ongoing compliance.
            </li>
          </OrderedList>
        </li>
      </OrderedList> */}
      <br />
      Please find reports of political campaigns run in the US <Link to="/reports" target="_blank">here</Link>.
      <br />
      <br />
      Please find all creatives of political campaigns run in the US <Link to="/creatives" target="_blank">here</Link>.
      <br />
    </PrivacyContainer>
  );
};

export { Privacy };
