type Row = {[k in string]: string};

export const csvToJSON = <Data>(csv: string): Data[] => {
    var lines=csv.split("\n");
    var result: Data[] = [];
    var headers=lines[0].split(",").map(header => header.trim());
  
    for(var i=1; i<lines.length-1; i++){
        var obj: Row = {};
        var currentline=lines[i].split(",");
        for(var j=0;j<headers.length;j++){
            obj[headers[j]] = currentline[j];
        }
        result.push(obj as Data);
  
    }

    return result;
}
